@import 'src/style/douglas-style';

.masterDataContainer {
  @extend .card;

  margin-top: 1rem;
  margin-bottom: 3rem;
}

.label {
  font-weight: 500;
  font-size: 1.4em;
  letter-spacing: 0.05em;
}

.articleNumber {
  font-size: 1.4em;
}

.articleMarking {
  font-size: 1.4em;
  display: flex;
  align-items: center;
}

.articleMarkingIcon {
  margin-right: 0.25rem;
  fill: $color-original-mint;
}
