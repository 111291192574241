.rootContainer {
  display: inline-block;

  button {
    margin: 2px !important;
    font-size: 1em;
  }
}

.selectElement {
  font-size: 0.75rem;
}

.formControl,
.formControlEntry {
  font-size: 0.75rem;
  min-width: 100px;
}

.languageButton {
  color: #252733;
}

.lightGrey {
  color: #979797;
}
