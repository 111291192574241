@import 'src/style/douglas-colors';

.dataGrid {
  min-width: 12rem;
}

.dataLabel {
  color: $color-form-label;
  font-size: 0.8rem;
  margin-top: 1rem;
  margin-right: 1rem;
  display: inline-block;
}
