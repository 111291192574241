@import 'src/style/douglas-style';

.progressHeadline {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 1.5rem;
}

.counterText {
  font-size: 1.125rem;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 0.5rem;
}

.progressBarRoot {
  height: 1.5rem;
  border-radius: 2.5rem;
  margin-bottom: 20px;
}

.progressBar {
  background-color: $color-page-background;
}

.action {
  justify-content: center;
}
