@import 'src/style/douglas-style';

.articleSelection {
  padding-left: 2.2rem;
}

.controls {
  display: flex;
  justify-content: space-between;
}

.gallerySelection {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.gallerySelectionLabel {
  margin-left: 2rem;
  margin-right: 1rem;
  font-size: 0.9rem;

  &:first-child {
    margin-left: 0;
  }
}

.ruler {
  border-bottom: 1px solid $color-medium-grey;
  margin: 1rem 0;
}

.arrowIcon {
  font-size: 48px;
}

.arrowContainer {
  display: flex;
  justify-content: center;
  margin-left: 2rem;
}

.dialogButton {
  font-size: 0.9rem;
}

.actions {
  display: flex;
  padding: 1rem;
}

.spacer {
  flex: 1 1 auto;
}

.selectedArticle {
  &.alreadyCopied {
    opacity: 0.2;
    pointer-events: none;
  }
}
