@import 'src/style/douglas-colors';

.root {
  background: #fff;
  padding: 1rem;
  font-size: 1rem;
  transition: transform 400ms ease-in, opacity 400ms;
  transform: none;
  opacity: 1;

  &.closing {
    transform: translateX(100%);
    opacity: 0.1;
  }
}

.articleSummary {
  font-size: 1.2rem;
  padding: 0 0 12px 16px;
  display: flex;
}

.accordionSummary {
  display: flex;
  align-items: center;
  width: 100%;
}

.accordionDetails {
  flex-direction: column;
  position: relative;
}

.loadingIndicator {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  background-color: rgba(255, 255, 255, 0.8);
}

.articleSelectionCheckbox {
  margin: -9px 0 -9px -9px;
}

.currentGallery {
  margin-top: 1rem;
}

.variantDetailLink {
  padding: 0 0.25rem;
}

.imageGallery {
  position: relative;
}
