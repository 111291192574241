.rootContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    border-radius: 1rem;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0 3rem;
  }
}
