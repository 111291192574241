.root {
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  &.noArticles {
    visibility: hidden;
  }
}

.buttons {
  display: flex;
}

.bulkActionButton {
  font-size: 0.85rem;

  &.reject {
    color: red;
    border-color: red;

    &:hover {
      background-color: rgba(255, 0, 0, 0.1);
    }
  }

  &:disabled {
    opacity: 0.25;
  }
}

.selectionText {
  width: 250px;
}
