@import 'src/style/douglas-colors';

.profileButton {
  margin: 2px;
  font-size: 1em;
  color: $color-near-black;
}

.menuIcon {
  color: $color-link-button-secondary-text;
}

.dividerLine {
  border-top: 1px solid $color-medium-grey;
}

.resetDatabase {
  color: red;
}

.error {
  padding: 2rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}

.loadingIndicator {
  width: 400px;
  height: 300px;

  :global(.fuse) {
    animation-name: shrinkfuse;
    animation-duration: 19.5s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }

  :global(.light) {
    animation-name: movefire;
    animation-duration: 18s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }

  :global(.fire) path {
    animation-name: fire;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &:nth-child(1) {
      animation-delay: -100ms;
    }

    &:nth-child(2) {
      animation-delay: -200ms;
    }

    &:nth-child(3) {
      animation-delay: -300ms;
    }

    &:nth-child(4) {
      animation-delay: -400ms;
    }

    &:nth-child(5) {
      animation-delay: -500ms;
    }

    &:nth-child(6) {
      animation-delay: -600ms;
    }

    &:nth-child(7) {
      animation-delay: -700ms;
    }

    &:nth-child(8) {
      animation-delay: -800ms;
    }
  }
}

@keyframes fire {
  0% {
    stroke-dashoffset: 100;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes shrinkfuse {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 130;
  }
}

@keyframes movefire {
  0% {
    transform: translate(0);
  }

  3% {
    transform: translate(0);
  }

  11.64% {
    transform: translate(1.8px, -11px);
  }

  22.60% {
    transform: translate(2.57px, -21.51px);
  }

  30.14% {
    transform: translate(2.39px, -31.44px);
  }

  37.67% {
    transform: translate(1.25px, -40.7px);
  }

  45.21% {
    transform: translate(-0.6px, -49.2px);
  }

  52.60% {
    transform: translate(-3.11px, -56.85px);
  }

  58.90% {
    transform: translate(-6.23px, -63.56px);
  }

  64.38% {
    transform: translate(-9.87px, -69.23px);
  }

  69.86% {
    transform: translate(-13.95px, -73.79px);
  }

  74.66% {
    transform: translate(-18.38px, -77.13px);
  }

  78.77% {
    transform: translate(-23.07px, -79.17px);
  }

  82.60% {
    transform: translate(-27.94px, -79.21px);
  }

  86.44% {
    transform: translate(-32.9px, -78px);
  }

  90.27% {
    transform: translate(-37.87px, -75.6px);
  }

  94.66% {
    transform: translate(-42.76px, -71.52px);
  }

  100% {
    transform: translate(-47.48px, -65.69px);
  }
}
