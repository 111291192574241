.root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.button {
  cursor: pointer;
}
