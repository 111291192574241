@import 'src/style/douglas-colors';

.root {
  display: flex;
  margin-left: 1rem;
  align-items: center;
}

.icon {
  fill: $color-dark-grey;
}

.popover {
  pointer-events: none;
}

.paper {
  padding: 0.5rem;
  color: $color-very-light-grey;
  background-color: rgba(0, 0, 0, 0.85);
}
