@import 'src/style/douglas-colors';

.tenantContainer {
  border: solid lightgray 1px;
  margin-bottom: 1.5rem;
  padding: 1rem 1rem 0.5rem;
}

.tenantFlagPanel {
  margin-top: -1.7rem;
  margin-bottom: 0.4rem;
  text-align: center;

  div {
    display: inline-block;
    background-color: white;
    width: fit-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  img {
    vertical-align: middle;
  }
}

.eanDetails {
  font-size: 1.1em;
  display: flex;
}

.galleryContainer {
  margin-top: 0.5rem;
}

.galleryButtonContainer {
  text-align: right;
}

.exportAgainButton {
  margin: 0;
  float: right;
  height: 100%;

  svg {
    margin-right: 0.4rem;
  }
}

.tenantDescription {
  display: flex;
  align-items: baseline;
}

.stockInfo {
  @extend .tenantDescription;

  svg {
    align-self: start;
  }
}

.formLabel {
  font-weight: bold;
  margin-right: 1rem;
}

.noGalleryText {
  font-style: italic;
  color: gray;
}

.noPermissionInfo {
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  border: 1px solid $color-mui-info-border-blue;
  //background-color: $color-mui-info-blue;
  color: $color-mui-info-text-blue;
  text-align: center;
}
