@import 'src/style/douglas-colors';

.title {
  font-weight: bold;
  font-size: 1.2rem;
  color: $color-dialog-headline-font;
  letter-spacing: 0.07rem;
  border-bottom: solid 1px $color-medium-grey;
  padding-right: 0;
}

.headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tenantInfo {
  display: flex;
  align-items: center;
}

.gallery {
  width: 800px;
}

.tenantMenuOption {
  display: flex;
  align-items: center;
  font-size: 0.85rem;

  &.hasNoGallery {
    opacity: 0.3;
  }
}

.tenantFlag {
  margin-right: 10px;
}

.tenantColumn {
  width: 150px;
  margin-right: 20px;

  .tenantLabelShrink {
    transform: translate(14px, -11px) scale(0.75);
  }

  .tenantSelection {
    width: 150px;
  }

  .targetTenant {
    padding: 0 14px;
  }
}

.row {
  display: flex;
  align-items: center;
}

.arrowIcon {
  font-size: 48px;
}

.arrowContainer {
  display: flex;
  justify-content: center;
  margin-left: 200px;
  width: 800px;
}
