.rootContainer {
  display: flex;
  background-color: #8a8a8a15;
  margin: 5px;
  border-radius: 4px;
  border: 1px solid #999;
  font-size: 1rem;
}

.searchInput {
  flex: 1;
  padding: 10px;
  color: white;
  margin: 0 3px;
}

.errorMessage {
  margin: 5px;
}

.errorMessage > div {
  padding: 0 16px;
}

.searchInput > input {
  padding: 0;
}

.searchIcon {
  color: white;
}
