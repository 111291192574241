@import 'src/style/douglas-style';

.root {
  height: calc(100vh - #{$topMenuHeight});
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding-bottom: 50px;
}

.list {
  width: 100%;
  height: 100%;
}

.filterPanel {
  display: flex;
  padding: 1rem;
  margin-top: 0;
}

.filter {
  padding: 0 1em 0 0;
  width: 200px;
}

.totalCount {
  font-size: 1.5em;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: $color-hint-text;
  text-align: right;
}

.bulkActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.3rem;
}
