$color-env-dev: #3cb2d6 !default;
$color-env-stage: #cea752 !default;

@import 'src/style/douglas-colors';
@import 'src/style/douglas-style';

.appTopBarRoot {
  flex-basis: $sideBarWidth;
}

.appTopBar {
  background-color: $color-topmenu-background;
  color: $color-topmenu-font;
  align-items: flex-end;
  box-shadow: none;
}

.appSideBar {
  background-color: $color-sidebar-background;
  color: $color-sidebar-font;
  max-width: $sideBarWidth;
  border-right: none;
}

.pageTitle {
  font-size: 1.5rem;
  font-weight: 500;
}

.toolbarHeadline {
  margin-bottom: 1rem;
}

.toolbarWrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: calc(100% - #{$sideBarWidth});
  padding-top: 2.5rem;
}

.profileBadge {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 1rem;
  margin-top: 1rem;
}

.logo {
  display: flex;
  //align-items: center;
  padding-top: 2.1rem;
  justify-content: center;
  flex-basis: $topMenuHeight;
  min-width: $sideBarWidth;

  a {
    text-decoration: none;
  }

  .appLink {
    position: relative;
  }

  .appName {
    color: $color-sidebar-font;
    font-family: 'Zahrah', serif;
    font-style: italic;
    font-weight: 600;
    font-size: 2.5em;
    letter-spacing: 0.5px;
  }

  .appName__Dev {
    color: $color-env-dev;
  }

  .appName__Stage {
    color: $color-env-stage;
  }

  .appNameSub {
    visibility: hidden;
    position: absolute;
    bottom: 0;
    right: 0;
    color: $color-sidebar-font;
    font-family: 'Zahrah', serif;
    font-style: italic;
    font-weight: 600;
    font-size: 2em;
    letter-spacing: 0.5px;
  }

  .appNameSub__Dev::after {
    content: 'DEV';
    visibility: visible;
    color: $color-env-dev;
  }

  .appNameSub__Stage::after {
    content: 'STAGE';
    visibility: visible;
    color: $color-env-stage;
  }
}

.menu {
  text-align: center;
  flex-grow: 1;
}

.instantSearchWrapper {
  margin-top: 1.5em;
}

.menuGroup {
  align-items: center;
  cursor: default;

  span {
    font-size: 1rem;
  }

  svg {
    color: $color-sidebar-font;
  }
}

.menuGroupLabelWithoutIcon {
  padding-left: 49px;
}

.menuGroupSelectionMarkerPlaceholder {
  width: 2px;
  margin-right: 1em;
}

.menuGroupContent {
  background-color: #5f5f5f;
}

@mixin menuEntryDefault {
  align-items: stretch;

  .menuSelectionMarker {
    width: 2px;
    margin-right: 1em;
  }

  span {
    font-size: 1rem;
  }

  svg {
    color: $color-sidebar-font;
  }
}

.menuEntry {
  @include menuEntryDefault;

  .menuSelectionMarker {
    background-color: transparent;
  }

  span {
    font-weight: 400;
  }
}

.menuEntrySelected {
  @include menuEntryDefault;

  background-color: #8a8a8a15 !important;

  .menuSelectionMarker {
    background-color: $color-original-mint;
  }

  span {
    font-weight: 500;
  }
}

.menuIcon {
  min-width: 35px;
  align-items: center;
}

.itemClickable {
  cursor: pointer;
}

.logoutButton {
  color: $color-topmenu-font;
  font-size: 1rem;
  font-weight: 400;
  justify-content: flex-start;
  margin: 0;
  min-width: 40px;
}

.logoutIcon {
  min-width: 35px;
}

.douglasLogo__Dev path {
  fill: $color-env-dev;
}

.douglasLogo__Stage path {
  fill: $color-env-stage;
}
