@import 'src/style/douglas-colors';

$image-width: 180px;

.panelRoot {
  background-color: $color-very-light-grey;
  width: 100%;
  padding: 1rem;
}

.imageCard {
  width: $image-width;

  &:hover {
    box-shadow: -1px 0.5rem 1rem 0 rgba(0, 0, 0, 0.8);
  }

  button {
    width: 100%;
    cursor: unset;
  }

  .image {
    width: $image-width;
    aspect-ratio: 1/1;
  }

  .imageSubtext {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 1rem;
    color: $color-hint-text;
  }

  .originalShopMarker {
    & > div {
      height: 1.3em;
      width: 1.3em;
      background: none;
      border: 1px solid $color-medium-grey;
      color: $color-hint-text;
    }
  }

  .imageUsedByNoneState {
    height: 1.3em;
    width: 1.3em;
    background: none;
    border: 1px solid $color-medium-grey;
    color: $color-hint-text;

    svg {
      height: 0.75em;
      width: 0.75em;
    }
  }

  .imageActionsContainer {
    padding: 0;
    justify-content: center;
  }

  .imageMenu {
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}

.closeDialogIcon {
  color: black;
  margin: -12px 0;
}

.dialogTitle {
  font-weight: bold;
  font-size: 1.2rem;
  color: $color-dialog-headline-font;
  letter-spacing: 0.07rem;
  border-bottom: solid 1px $color-medium-grey;
  padding-right: 0;

  .headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .contextInformation {
    display: flex;
    align-items: center;
  }
}

.confirmDialogImageContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
