@import 'src/style/douglas-colors';

.panels {
  display: flex;
  width: 700px;
}

.panel {
  width: 50%;
}

.listItem {
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  &.highlight {
    background-color: $color-light-grey;
  }
}

.paper {
  width: 100%;
  padding: 10px 0;
  overflow: auto;

  &.highlightInactive .listItem:not(:hover) {
    background-color: transparent;
  }
}

.actions {
  margin-top: 20px;
  display: flex;
}

.actionSpace {
  flex-grow: 1;
}

.filterInput {
  width: 100%;
  margin-top: 10px;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1rem;
}

.clearFilter {
  cursor: pointer;
}

.details {
  margin-left: 0.5rem;
  color: #8a8a8a;
}
