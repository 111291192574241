@import 'src/style/douglas-style';
@import 'src/style/material-ui-breakpoints';

.dataContainer {
  @extend .card;
}

.saveButtonContainer {
  margin-bottom: 0.25rem;
  text-align: right;

  span {
    @include xs {
      font-size: 0.7em;
    }

    @include sm {
      font-size: 1em;
    }
  }

  svg {
    margin-right: 0.5rem;
  }
}

.missingArticleHint {
  margin-top: 35px;
}

.noGalleryHint {
  font-style: italic;
  padding-bottom: 0.5rem;
}
