.imageSlot {
  flex: 1 0;
  width: 8%;
  user-select: none;
  margin-left: 0.2rem;

  &:first-child {
    margin-left: 0;
  }

  &:nth-child(9) {
    margin-left: 0.5rem;
  }

  &:nth-child(10),
  &:nth-child(11) {
    margin-left: 0.25rem;
  }

  &.canDrop .dropIndicator {
    visibility: visible;
  }

  &.isOver .dropIndicator {
    opacity: 0.5;
  }
}

.slotLabel {
  text-align: center;
  font-size: 0.7rem;
  white-space: nowrap;

  &.small {
    font-size: 0.5rem;
  }
}
