@import 'converter';
@import 'douglas-colors';
@import 'material-ui-breakpoints';

$topMenuHeight: 93px;
$sideBarWidth: 240px;
$screen-mx-container-width: $container-max-width + $sideBarWidth;

// general tags:
// -------------
body {
  background: $color-page-background;
}

h1 {
  font-weight: 600;
  font-size: 1.5em;
}

strong {
  font-weight: 500;
}

// divider:
// ----------
@mixin divider {
  display: block;
  margin: calculateRem(15) 12.33%;
  //margin-top: 15px;
}

@mixin thin-divider {
  @include divider;

  height: 1px;
}

@mixin bold-divider {
  @include divider;

  height: 3px;
}

div.thin-divider-black {
  @include thin-divider;

  background-color: $color-black;
}

div.thin-divider-dark-grey {
  @include thin-divider;

  background-color: $color-dark-grey;
}

div.thin-divider-medium-grey {
  @include thin-divider;

  background-color: $color-medium-grey;
}

div.thin-divider-mint {
  @include thin-divider;

  background-color: $color-bright-mint;
}

div.thin-divider-white {
  @include thin-divider;

  background-color: $color-white;
}

div.bold-divider-black {
  @include bold-divider;

  background-color: $color-black;
}

div.bold-divider-medium-grey {
  @include bold-divider;

  background-color: $color-medium-grey;
}

div.bold-divider-bright-mint {
  @include bold-divider;

  background-color: $color-bright-mint;
}

div.bold-divider-medium-mint {
  @include bold-divider;

  background-color: $color-medium-mint;
}

div.bold-divider-light-mint {
  @include bold-divider;

  background-color: $color-light-mint;
}
// ----------
// end: divider

// card elements
$card-padding: 2rem;

.card {
  background-color: $color-white;
  padding: $card-padding;
  border: 1px solid $color-divider-secondary-color;
  border-radius: 8px;
}

.card-with-shadow {
  @extend .card;

  box-shadow:
    0 1px 1px rgba(0, 0, 0, 0.14),
    0 2px 1px rgba(0, 0, 0, 0.12),
    0 1px 3px rgba(0, 0, 0, 0.2);
}

// ----------
// end: card elements

// ////////////////////////////
// Alert Messages
// ////////////////////////////

.MuiAlert-standardError {
  border: 1px solid #e6373c;
  border-radius: 4px;
}

.MuiAlert-standardError,
.MuiAlert-standardInfo {
  border-radius: 4px;
}

.alertInfoStandard {
  padding-top: 0;
  padding-bottom: 0;
  border: 1px solid $color-mui-info-border-blue;
  background-color: $color-mui-info-blue;
  color: $color-mui-info-text-blue;

  .MuiAlert-icon {
    color: $color-mui-info-text-blue;

    .MuiSvgIcon-root {
      color: $color-mui-info-text-blue;
    }
  }
}

.MuiAlert-standardInfo {
  @extend .alertInfoStandard;
}

.MuiAlert-standardSuccess {
  //border: 1px solid #3cbeaf;
}

// ////////////////////////////
// Image Zoom
// ////////////////////////////

.ril__image {
  z-index: 100;
}

.ril__toolbar {
  z-index: 101;

  button {
    outline: none;
  }
}

// ////////////////////////////
// App Bar
// ////////////////////////////

.MuiAppBar-positionFixed {
  z-index: 10;
}

// ////////////////////////////
// Date Range Picker
// ////////////////////////////

.DateRangePicker,
.DateRangePickerInput {
  width: 100%;
}

// ////////////////////////////
// Dialog Box
// ////////////////////////////

.MuiDialog-paperWidthXl {
  max-height: 90vh;
}
