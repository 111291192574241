@import 'src/style/douglas-colors';

.root {
  display: flex;
}

.flag {
  display: flex;
  align-items: center;
  margin-right: 1rem;

  img {
    margin-top: 1rem;
  }
}

.status {
  flex: 1 1 auto;
  position: relative;
}

.flag,
.imageGallery,
.invalidArticle {
  height: 120px;
  position: relative;
}

.invalidArticle {
  color: red;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
}

.alertContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert {
  margin-top: 1rem;
  z-index: 1;

  &.warning {
    border: 1px solid $color-mui-notification-orange;
  }
}

.alertIconContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.alertIcon {
  margin: -3px;

  svg {
    font-size: 1.6rem;
  }

  &.error svg {
    fill: $color-mui-error-red;
  }

  &.warning svg {
    fill: $color-mui-notification-orange;
  }
}
