@import 'src/style/douglas-colors';

.guidelineLink {
  display: flex;
}

.linkIcon {
  margin-right: 0.25rem;
}

.inlineLink {
  color: $color-default-text;
  text-decoration: underline;
}
