.label {
  margin-left: 2rem;
  margin-right: 1rem;
  font-size: 0.9rem;

  &:first-child {
    margin-left: 0;
  }
}

.tenantSelection {
  width: 12rem;
}
