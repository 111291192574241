@import 'src/style/douglas-colors';
@import 'src/style/douglas-style';

.cardBox {
  @extend .card;

  padding: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.stepNumberText {
  font-family: 'Zahrah', serif;
  font-size: 4rem;
  font-style: italic;
  padding-bottom: 1.2rem;
}

.headline {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.subtitle {
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.stepContent {
  padding-top: 1.5rem;
  width: 100%;
  text-align: center;
}
