.alert {
  margin-bottom: 10px;
}

.message {
  flex-grow: 1;
}

.progress {
  margin-top: 10px;
}
