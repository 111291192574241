@import 'src/style/douglas-colors';

.dropContainer {
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-color: #ccc;
  border-style: dashed;
  background-color: #fafafa;
  color: #777;
  outline: none;
  transition: border 250ms ease-in-out;
  margin-bottom: 20px;
}

.warning {
  margin: 10px 0;
}

.uploadAction {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.errorMessage {
  margin: 10px 0;
}

.closeDialogTitle {
  display: flex;
  justify-content: space-between;
}

.closeDialogIcon {
  margin: -12px;
}

.errorTable {
  max-height: 400px;
}

.errorReport {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
