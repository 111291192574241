.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  height: 3rem;
}

.articleSummary {
  display: flex;
  align-items: center;
}

.selectionCheckbox {
  margin-right: 0.3rem;
}
