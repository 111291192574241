@import 'src/style/douglas-colors';

.selectionEntry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.selectionIcon {
  background-color: $color-white;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px $color-checkbox-border, inset 0 -2px 0 $color-checkbox-border;
}

.checkedIcon {
  color: $color-original-mint;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px $color-original-mint, inset 0 -2px 0 $color-original-mint;
}

.selectionDivider {
  background-color: $color-divider-secondary-color;
  width: 100%;
  height: 1px;
}
