@import 'src/style/douglas-style';
@import 'src/style/douglas-colors';

.appRoot {
  display: flex;
}

.appContent {
  flex-grow: 1;
  margin-top: $topMenuHeight;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}
