@import 'src/style/douglas-style';

.rootContainer {
  height: calc(100vh - #{$topMenuHeight});
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding-bottom: 50px;
}

.listTopContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.openChecksStatus {
  font-size: 1.5em;
  font-weight: bold;
  letter-spacing: 0.5px;
  padding-right: 20px;
  margin-bottom: 12px;
  color: $color-hint-text;
}

.openChecksLabel {
  margin-right: 1rem;
}

.filter {
  margin-right: 2.5em;
}

.errorMessage {
  margin-bottom: 1em;
}
