@import 'src/style/douglas-style';
@import 'src/style/douglas-colors';

.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loadingInfoText {
  font-size: 2em;
}
