@import 'src/style/douglas-style';

.root {
  display: flex;
  align-items: center;
}

.navigationButton {
  margin-bottom: 1rem;

  &.warning {
    background-color: lighten($color-notification-orange, 40%);

    &:hover {
      background-color: lighten($color-notification-orange, 35%);
    }
  }

  &.error {
    background-color: lighten($color-error-red, 40%);

    &:hover {
      background-color: lighten($color-error-red, 35%);
    }
  }
}

.navigationIcon {
  font-size: 2rem;

  &.warning {
    fill: $color-notification-orange;
  }

  &.error {
    fill: $color-error-red;
  }
}

.options {
  display: flex;
  margin: 0 1rem;
}

.option {
  border-radius: 5px;
  padding: 0.1rem;
  margin: 0 0.25rem;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 8px;
  }

  &.selected {
    background-color: $color-very-light-grey;
  }

  &.disabled {
    opacity: 0.2;
  }
}

.emptyOption {
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 0.25rem;
}

.optionBubble {
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  border: 1px solid transparent;

  &:first-child {
    margin-bottom: 0.1rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &.warning svg {
    fill: $color-notification-orange;
  }

  &.error svg {
    fill: $color-error-red;
  }

  &.normal svg {
    fill: $color-success-green;
  }
}

.currentPage {
  text-align: center;
}
