@import 'src/style/douglas-colors';

.brandFilter {
  display: inline-flex;
  flex-direction: row;
}

.multiFilterItem {
  padding-top: 0;
  padding-bottom: 0;
}

.multiFilterTrigger {
  display: none;
}

.dateDeleteIcon {
  cursor: pointer;
  color: #0000008a;
}
