@import "src/style/douglas-colors";

.blockingLayerRoot {
  background-color: $color-dark-grey;
  opacity: 0.5;
  flex: 1;
  width: 100%;
  z-index: 999;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: inherit;
  cursor: wait;
}
