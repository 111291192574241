@import 'src/style/douglas-colors';

.headline {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.formControl {
  margin: 1em;
  min-width: 120px;
}

.checkedRadioIcon {
  color: $color-original-mint;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px $color-original-mint, inset 0 -2px 0 $color-original-mint;
}

.selectionRadioIcon {
  background-color: $color-white;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px $color-checkbox-border, inset 0 -2px 0 $color-checkbox-border;
}

.resyncSettingsContainer {
  min-height: 300px;
}

.resyncOptionSelection {
  margin-top: 1rem;
}

.resyncBrandSelection {
  margin-top: 1rem;
}

.resyncTenantSelection {
  margin-top: 2em;
}

.resyncBrandSelectionContainer {
  margin-top: 1rem;
}

.fieldValidationErrorMessage {
  color: $color-error-red;
  font-size: 0.8em;
}

.resyncButtonContainer {
  margin-top: 1rem;
}

.userButtons {
  display: flex;
  justify-content: space-between;
}
