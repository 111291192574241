@import 'src/style/douglas-colors';

.root {
  margin-top: 2rem;
}

.accordionSummary {
  font-size: 1rem;
}

.accordionHeader {
  width: 15.2rem;
}

.accordionSecondHeader {
  color: #8a8a8a;
}

.accordionDetails {
  flex-direction: column;
}

.brandSubscriptions,
.brandSubscriptions:global(.MuiAccordion-root.Mui-expanded) {
  margin: 0 -1rem;
  width: calc(100% + 2rem);
}

.localStorageNotification {
  color: #8a8a8a;
  text-align: center;
  padding-top: 1rem;
}
