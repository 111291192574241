@import 'src/style/douglas-colors';

.notification {
  margin-right: 1rem;
}

.link {
  color: inherit;
  padding: 0 0.1rem;
}

.alert {
  z-index: 1;

  &.warning {
    border: 1px solid $color-mui-notification-orange;
  }
}
