@import 'src/style/douglas-style';

.resultDownloadPanel {
  @extend .card;

  margin-top: 1rem;
}

.errorInfoLabel {
  color: $color-error-red;
  font-weight: bold;
  font-size: 1.2em;
}

.successInfoLabel {
  font-size: 1.2em;
}

.linkButton {
  display: block;
  font-size: 0.9em;
  background-color: $color-link-button-background;
  color: $color-link-button-text;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: 0;
  text-decoration: none;
  letter-spacing: 0.2px;

  &:hover {
    text-decoration: none;
  }
}

.linkIcon {
  vertical-align: middle;
  width: 1.5em;
}
