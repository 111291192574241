.reviewList {
  flex-grow: 1;
  overflow-y: hidden;
  overflow-x: hidden;
  min-height: 0;
  scroll-behavior: smooth;
}

.jumpToTopButton {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
}
