@import 'src/style/douglas-colors';

.accordion {
  margin-bottom: 40px !important;
  border: 1px solid $color-divider-secondary-color;
  border-radius: 8px;

  &::before {
    content: none;
  }
}

.accordionDetails {
  flex-direction: column;
}

.imageSuggestion {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.suggestionCountBadge > span {
  transform: none;
  position: relative;
  z-index: unset;
}
