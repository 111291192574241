.tenantLabel {
  display: flex;
  align-items: center;
  font-size: 0.85rem;

  &.disabled {
    opacity: 0.3;
  }
}

.tenantFlag {
  margin-right: 1rem;
}
