@import "src/style/douglas-colors";

.dataContainer {
  width: 100%;
  margin-top: 1rem;
}

.dataLabel {
  color: $color-form-label;
  font-size: 0.8rem;
}

.dataValue {
  font-size: 1rem;
  margin-top: 0.25rem;
}
