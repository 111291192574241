@import "src/style/douglas-colors";

.fieldset {
  border: 1px solid $color-fieldset-border;
  border-radius: 4px;
}

.fieldsetLegend {
  color: $color-fieldset-font;
}

.fieldsetContent {
  padding: 8px 16px;
}
