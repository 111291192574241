.rootContainer {
  font-size: 1rem;
}

.headerLabel {
  font-size: 5rem;
}

.errorMessage {
  margin-top: 0.5rem;
  font-weight: bold;
  font-size: 1.1rem;
}

.errorHint {
  margin-top: 0.5rem;
  color: #9fa2b4;
}

.redirectLink {
  text-decoration: none;
}

.redirectHomeButton {
  width: 260px;
}

.textWrapper {
  margin: 0.4rem;
}
