@import "src/style/douglas-colors";

.root {
  border-radius: 1rem;
  font-size: 1.1rem;
  border: 1px solid $color-very-dark-grey;
  display: flex;
  padding: 1.5rem;
  background-color: $color-light-grey;
  color: $color-near-black;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 150px;
  min-height: 150px;
}

.messageContainer {
  padding-left: 1.5rem;
  overflow-wrap: break-word;
  width: calc(100% - 150px);
  display: flex;
  align-items: center;
}

.message {
  width: 100%;
}

.refresh {
  font-size: 0.9rem;
  display: inline-block;

  &:nth-child(n+2) {
    margin-top: 1rem;
  }
}
