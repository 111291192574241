.gallery {
  display: flex;
}

.draggableImage {
  width: 8%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 0.25rem;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}
