@import "src/style/douglas-colors";

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: $color-original-mint;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid $color-original-mint;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}
