.buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;

  &.hasErrorMessage {
    justify-content: space-between;
  }
}

.rejectAll {
  color: red;
  border-color: red;

  &:hover {
    border-color: red;
    background-color: rgba(255, 0, 0, 0.1);
  }
}
