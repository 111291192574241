@import 'src/style/douglas-style';

.feedBackRoot {
  height: calc(100vh - #{$topMenuHeight});
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding-bottom: 50px;
}

.listTopContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.listTypeTabContainer {
  margin: 1rem 0;
}
