@import 'src/style/douglas-style';

.root {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert {
  border: 1px solid $color-success-green;
}

.progress {
  margin-top: 1rem;
}

.progressBar {
  transition: none !important;
}
