@import 'src/style/douglas-colors';

.root {
  display: flex;
  align-items: center;
  width: 100%;
}

.selectionCheckbox {
  margin-left: -9px;
}

.tenantFlag {
  margin: 0 0.5rem;

  &.noImages {
    filter: drop-shadow(0 0 3px rgba($color-error-red, 0.8));
  }
}

.galleryName {
  font-weight: bold;
}

.tenantDescription {
  display: flex;
  font-size: 1em;
}

.tenantDetails {
  color: $color-very-dark-grey;
  padding-left: 1rem;
  display: flex;
  align-items: center;
}

.goLiveDateLabel {
  padding-right: 0.5rem;
}

.noImages {
  color: $color-error-red;
}

.noPermissionInfo {
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  border: 1px solid $color-mui-info-border-blue;
  color: $color-mui-info-text-blue;
  text-align: center;
}
