@import 'src/style/douglas-colors';

.title {
  font-weight: bold;
  font-size: 1.2rem;
  color: $color-dialog-headline-font;
  letter-spacing: 0.07rem;
  border-bottom: solid 1px $color-medium-grey;
  padding-right: 0;
}

.headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
