@import 'src/style/douglas-colors';

.stockStatusUnknownText {
  font-style: italic;
  color: $color-hint-text;
}

.stockIcon {
  vertical-align: text-bottom;
  margin-right: 0.2rem;
  padding: 0.125rem;
}

.stockIconPositive {
  @extend .stockIcon;

  color: $color-success-green;
}

.stockIconNegative {
  @extend .stockIcon;

  color: $color-dark-grey;
}
