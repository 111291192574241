
// colorgroup "mint to white":
$color-original-mint: #3cbeaf !default;
$color-very-dark-mint: #0b2e2a !default;
$color-bright-mint: #9bdcd2 !default;
$color-medium-mint: #c3eae5 !default;
$color-light-mint: #e6f6f4 !default;
$color-shiny-mint: #f0faf8 !default;
$color-white: #fff;

// colorgroup "black to grey":
$color-black: #000 !default;
$color-near-black: #252733 !default;
$color-very-dark-grey: #bababa !default;
$color-dark-grey: #bfbfbf !default;
$color-medium-grey: #e6e6e6 !default;
$color-light-grey: #e2e2e2 !default;
$color-very-light-grey: #f2f2f2 !default;

// colorgroup "red to rose":
$color-pink: #ea46bf !default;
$color-sale-red: #ac145a !default;
$color-beauty-card-bright-rose: #f0c3dc !default;
$color-beauty-card-light-rose: #ffebf0 !default;
$color-beauty-card-dark-rose: #e17db4 !default;

// colorgroup "alerts":
$color-success-green: #5fc33c !default;
$color-notification-orange: #ff9b14 !default;
$color-error-red: #e6373c !default;
$color-error-red-background: #ffbaba !default;

// MUI elements
$color-mui-success-green: #5fc33c;
$color-mui-info-blue: #e8f4fd;
$color-mui-notification-orange: #ff9b14;
$color-mui-error-red: #e6373c;

$color-mui-info-border-blue: #2196f3;

$color-mui-info-text-blue: #0d3c61;

// standard elements:
$color-dialog-headline-font: #514f4f !default;

$color-link-button-background: $color-medium-grey;
$color-link-button-text: $color-black;
$color-link-button-secondary-text: #9fa2b4 !default;
$color-button-secondary-text: #797979;

$color-title-secondary-text: #9fa2b4 !default;
$color-form-label: #8a8a8a !default;

$color-checkbox-border: #c5c7cd !default;

$color-divider-secondary-color: #dfe0eb !default;

$color-fieldset-border: #bfbfbf;
$color-fieldset-font: #252733;

$color-page-context-info-container-background: #f7f7f7;

// other colors
$color-page-background: #f2f2f2 !default;
$color-default-text: $color-black !default;
$color-sidebar-background: #363740 !default;
$color-sidebar-font: #f2f2f2 !default;
$color-topmenu-background: $color-page-background !default;
$color-topmenu-font: $color-near-black !default;

$color-no-data-text: #514f4f;

$color-hint-text: #8a8a8a !default;

$color-table-bg: $color-white;
