@import 'src/style/douglas-style';

.container {
  @extend .card;
}

.headline {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.detailsTitle {
  color: $color-title-secondary-text;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.2px;
}

.closeIconContainer {
  flex-grow: 1;
  text-align: end;
  padding: 0 !important;
  margin-top: -8px;
  margin-bottom: -4px;
}

.iconButton {
  padding: 0;
  margin-right: calc(#{$card-padding} / -2);
  border: solid 1px $color-dark-grey;
  border-radius: 50%;

  svg {
    font-size: 0.875rem;
  }
}

.detailsContent {
  align-items: center;
  text-align: center;

  .detailsInfoText {
    padding-left: 1em;
    flex-direction: column;
    text-align: left;

    .filenameText {
      font-weight: 500;
      font-size: calculateRem(14);
      color: $color-near-black;
      line-height: 20px;
      letter-spacing: 0.2px;
    }
  }
}

@mixin statusTextMixin {
  letter-spacing: 0.1px;
  font-size: calculateRem(12);
  line-height: 1rem;
}

.statusTextDefault {
  @include statusTextMixin;

  color: $color-hint-text;
}

.statusTextError {
  @include statusTextMixin;

  color: $color-error-red;
}

.statusTextSuccess {
  @include statusTextMixin;

  color: $color-success-green;
}

.statusTitle {
  color: $color-title-secondary-text;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.2px;
}

.statusContent {
  display: flex;
  align-items: flex-start;
}

.statusIconError {
  color: $color-error-red-background;
}

.emptyListMessage {
  color: $color-title-secondary-text;
  width: 100%;
  text-align: center;
  letter-spacing: 0.5px;
  font-style: italic;
  font-size: 1.25rem;
  margin-top: 3rem;
}

.slimSpacer {
  height: 2em;
}

.spacer {
  height: 3em;
}

.divider {
  height: 1px;
  width: calc(100% + 2 * #{$card-padding});
  background-color: $color-divider-secondary-color;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: -$card-padding;
}

.processingIcon {
  padding: 3px;
}

.clearHistoryButton {
  font-size: 0.875rem;
  font-weight: normal;
  text-align: right;
  letter-spacing: 0.3px;
  color: $color-link-button-secondary-text;
}
