@import 'src/style/douglas-colors';

.dialogBase {
  position: relative;
}

.dialogXs {
  width: 330px;
}

.dialogSm {
  width: 390px;
}

.dialogMd {
  width: 460px;
}

.dialogLg {
  width: 540px;
}

.dialogXl {
  width: 800px;
}

.dialogContainer {
  padding: 1rem 2rem 2.3rem;
  border-bottom: none;
}

.dialogActionContainer {
  padding: 24px 32px;
}

.closeDialogIcon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px 24px;
  color: $color-dialog-headline-font;

  &:hover {
    background-color: transparent;
  }
}

.dialogTitle {
  padding-left: 32px;
}

.dialogTitleText {
  font-weight: bold;
  font-size: 1.2rem;
  color: $color-dialog-headline-font;
  letter-spacing: 0.1rem;
}

.dialogButtonCancel {
  color: $color-button-secondary-text;
}

.dialogButtonSubmit {
  letter-spacing: 1.25px;
  font-size: 0.875rem;
  text-transform: uppercase;
}
