@import 'src/style/douglas-style';

.title {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 1rem;
  fill: $color-error-red;
}

.content {
  padding-top: 2rem;
}
