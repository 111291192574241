@import '/src/style/douglas-colors';

.articleHeadlineRoot {
  display: flex;
  font-size: 0.85em;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.selection {
  display: flex;
  flex-basis: 0;
  flex-shrink: 2;
  align-items: flex-start;

  & > span {
    padding: 0 9px;
    margin-left: -10px;
  }
}

.articleHeader {
  font-size: 140%;
  flex-basis: 42%;
  flex-grow: 2;

  &.flag {
    flex-basis: 5%;
  }
}

.linkIcon {
  margin-left: 0.25rem;
  height: 0.9em;
  width: 0.9em;
}
