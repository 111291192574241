@import 'src/style/douglas-colors';

.pagination {
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
}

.table {
  background: $color-table-bg;
}

.header {
  background: $color-bright-mint;

  th {
    vertical-align: top;
    font-size: 1.3em;
  }
}

.row {
  background-color: $color-white;

  &:nth-child(4n + 3),
  &:nth-child(4n + 4) {
    background: $color-light-mint;
  }
}

.detailRow {
  > td {
    border-bottom: none;
    padding-left: 3em;
    padding-right: 0;
  }
}

.detailPanel {
  margin: 0;
}

.subTable {
  border: none;
}

.subTableHeader {
  color: $color-very-dark-grey;
}

.cell {
  border-bottom: none;
}

.detailCell {
  padding-top: 0;
  padding-bottom: 0;
}

.checkbox {
  margin: -10px 0 0 -10px;
}

.link {
  color: inherit;
}
