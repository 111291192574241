@import 'src/style/douglas-style';

@use 'sass:color';

$color-summary-bg-anysubstitution: #fff5e7;
$color-summary-bg-anysubstitution-secondary: scale-color(
  $color-summary-bg-anysubstitution,
  $lightness: 50%,
  $saturation: -30%
);

$color-summary-bg-nosubstitution: $color-mui-info-blue;
$color-summary-bg-nosubstitution-secondary: scale-color(
  $color-mui-info-blue,
  $lightness: 50%,
  $saturation: -30%
);

.feedbackListContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.closeButton {
  cursor: pointer;
}

.listItemRoot {
  margin-bottom: 1rem;
  transition: transform 400ms ease-in, opacity 400ms;
  transform: none;
  opacity: 1;
  position: relative;
}

.closedListItemRoot {
  transition: transform 400ms ease-in, opacity 400ms;
  overflow: hidden;
  transform: translateX(100%);
  opacity: 0.1;
}

.listItemContainer {
  @extend .card;
}

.galleryFeedbackSummaryContainer {
  font-size: 1.2em;
}

.feedbackImageGallery {
  display: flex;
}

.divider {
  margin: 8px 0;
  flex-basis: 100%;
  background-color: black;
}

.noGalleryFeedbackSummaryRoot {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 2rem;
  border-radius: 4px;
}

.noGalleryFeedbackSummary {
  padding: 0 1rem;
}

.galleryFeedbackSummaryRoot {
  border-radius: 4px;
  padding-left: 2rem;
}

.galleryFeedbackSummaryHeadlineText {
  font-size: 1.2em;
  font-weight: 500;

  svg {
    margin-left: -2rem;
    margin-right: 0.9rem;
  }
}

.galleryFeedbackSummarySubHeadlineText {
  font-weight: inherit;
  font-size: 1em;
}

.galleryFeedbackSummaryDetails {
  padding-left: 2rem;
  padding-bottom: 0.5rem;
  margin-right: 2rem;
}

.summaryFeedbackText {
  display: flex;
  padding: 0.5rem 0;
  flex-direction: column;
}

.detailFeedbackTextGallery {
  margin: 0.5rem 0;
}

.detailFeedbackTextImages {
  margin: 0.5rem 0;

  ul {
    list-style-type: none;
  }
}

.anySubstitutionRequiredIndicator {
  background-color: $color-summary-bg-anysubstitution;
  color: #5c1618;

  .galleryFeedbackSummaryDetails {
    background-color: $color-summary-bg-anysubstitution-secondary;
  }
}

.noSubstitutionRequiredIndicator {
  background-color: $color-summary-bg-nosubstitution;
  color: $color-mui-info-text-blue;

  .galleryFeedbackSummaryDetails {
    background-color: $color-summary-bg-nosubstitution-secondary;
  }
}

.actionButtonContainer {
  margin-right: 1rem;
}

.navActionButton {
  text-transform: uppercase;
  opacity: 0.75;
}
