@import 'src/style/douglas-colors';
@import 'src/components/ImageGallery/imageGalleryConstants';

.imageGallery {
  width: 100%;
  display: flex;
}

.image,
.imageZoom,
.dropIndicator,
.imageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.imageWrapper {
  background-color: white;
}

.image {
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.imageZoom {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  opacity: 0;
}

.inheritedPopover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
}

.inheritedIcon {
  display: block;
  fill: #777;
}

.imageContainer {
  content: '';
  display: block;
  position: relative;
  padding-bottom: 100%;
  background-color: $color-medium-grey;

  &.empty {
    box-shadow: inset 0 4px 20px rgba(0, 0, 0, 0.2);
  }

  &:hover .imageZoom {
    opacity: 1;
  }

  &:hover .inheritedIcon {
    fill: #888;
  }
}

.dropIndicator {
  background-color: green;
  z-index: 3;
  pointer-events: none;
  opacity: 0.2;
  visibility: hidden;

  &.canDrop {
    visibility: visible;
  }

  &.isOver {
    opacity: 0.5;
  }
}

.zoomIcon {
  font-size: 2rem;
  fill: #fff;
}

.imageCard {
  border-radius: 5%;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  border: 1px solid $color-medium-grey;
  transition: background-color 250ms ease, border-color 250ms ease;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 8px;
  }

  &.approved {
    border-color: $color-very-dark-grey;
  }

  &.suggested {
    border-color: green;
    background-color: rgba(0, 255, 0, 0.2);
  }

  &.rejected {
    border-color: red;
    background-color: rgba(255, 0, 0, 0.2);
  }
}

.image.global {
  opacity: 0.4;
}

.reviewIcon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;

  .suggested {
    fill: green;
  }

  .rejected {
    fill: red;
  }
}

.popover {
  font-size: 1.1em;
  pointer-events: none;

  .paper {
    padding: 1rem;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.85);
  }
}
