.link {
  color: inherit;
}

.pageLink {
  color: #a9a3a6;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-weight: 500;
  font-size: 1.1em;
  text-decoration: none;
}
