.alertListContainer {
  margin-bottom: 1rem;
}

.errorListContainer {
  margin-bottom: 1rem;
}

.infoListContainer {
  margin-bottom: 1rem;
}
