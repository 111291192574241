.summaryCountText {
  font-size: 3em;
  font-weight: 100;
  text-align: center;
  line-height: 1.1em;
}

.summaryLabel,
.summaryLegal {
  font-size: 0.65rem;
  text-align: center;
}

.summaryLink {
  text-decoration: underline;
  cursor: pointer;
  color: #3cbeaf;
}

.spacer {
  height: 2rem;
}

.closeDialogIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: black;
}
