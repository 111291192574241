@import 'src/style/douglas-colors';
@import 'src/style/douglas-style';

.helperBox {
  @extend .card;

  margin-bottom: 1rem;
  text-align: left;

  p {
    color: $color-hint-text;
    line-height: 1.7em;
    margin-block-start: 0.5em;
    letter-spacing: 0.1px;
  }
}

.headline {
  font-weight: 500;
  color: $color-near-black;
}

.hintList {
  color: $color-hint-text;
  padding-inline-start: 2em;
  list-style-type: none;
  padding-left: 1em;

  li {
    margin-top: 1em;
    letter-spacing: 0.1px;
  }

  li::before {
    content: '–';
    position: absolute;
    margin-left: -1em;
  }
}
