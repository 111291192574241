@import 'src/style/douglas-colors';
@import 'src/style/douglas-style';

.rootContainer {
  border: 2px dashed #e9e9e9;
  border-radius: 2px;
  padding: 0.3em;
  min-height: 120px;
  cursor: pointer;
  background-color: #fcfcfc;

  &:focus {
    outline-color: transparent;
    border: 2px dashed #e9e9e9;
  }
}

.uploadContainer {
  width: 60px;
  height: 50px;
  margin: auto;
  border-radius: 3px;
  border: 1px #e9e9e9 solid;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadIcon {
  height: 34px;
  width: 34px;
}

.dropAndDropHint {
  font-size: 0.65rem;
  text-align: center;
  color: $color-hint-text;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
