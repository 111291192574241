
.legalHints {
  font-size: 1.1em;
  margin: 0.5rem 0;
  padding-left: 0.5rem;
}

.legalHintLink {
  display: inline-block;
  font-size: 12px;
}

.legalHintLink a {
  text-decoration: none;
  color: inherit;
}

.legalHintLink a:hover {
  text-decoration: underline;
}

.legalHintLink_LegalNote {
  margin-right: 0.4em;
}

.legalHintLink_DataProtection {
  margin-left: 0.4em;
}
