.rootContainer {
  background-color: #f2f2f2;
  text-align: center;
  padding: 0.3em 0;
  cursor: pointer;
  user-select: none;
  position: relative;
  max-width: 100%;
  flex-basis: 100%;

  svg {
    vertical-align: text-bottom;
  }
}

.columnHeaderActions {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.content {
  max-width: 100%;
  flex-basis: 100%;
}
